<template>
  <div class="centered-wrapper container-wrapper">
    <Logo class="logo" />
    <FormWrapper @submit="connectToServer">
      <Separator><h2 class="main-title">Профиль</h2></Separator>
      {{ globalStore }}
      <BaseInput
        v-model.trim="profile.second_name"
        placeholder="Фамилия"
        type="text"
        class="u-mb--1"
      />
      <BaseInput v-model.trim="profile.name" placeholder="Имя" type="text" class="u-mb--1" />
      <BaseInput
        v-model.trim="profile.last_name"
        placeholder="Отчество"
        type="text"
        class="u-mb--1"
      />
      <Button width="100%" class="u-mb--1" @click.prevent="saveMe"> Сохранить </Button>
      <Separator>Привязка телефона</Separator>
      <BaseInput
        v-model.trim="profile.phone"
        placeholder="Введите телефон"
        type="number"
        class="u-mb--1"
      />
      <BaseInput
        v-if="codeSended"
        v-model.trim="smsCode"
        placeholder="Введите код"
        type="number"
        class="u-mb--1"
      />
      <Button
        width="100%"
        class="u-mb--1"
        background="--success"
        @click.prevent="sendCheckSmsCode"
        v-if="codeSended"
      >
        Подтвердить
      </Button>
      <Button width="100%" class="u-mb--1" @click.prevent="sendSmsCode"> Выслать код </Button>

      <Separator>Привязка емейла</Separator>
      <BaseInput
        v-model.trim="profile.email"
        placeholder="Введите Email"
        type="text"
        class="u-mb--1"
      />
      <Button width="100%" class="u-mb--1" @click.prevent="sendEmailActivationLink">
        Выслать ссылку активации
      </Button>

      <Separator>Смена пинкода</Separator>
      <BaseInput
        label="Текущий пин код"
        v-model.trim="old_pin_code"
        placeholder="Введите текущий пин код"
        type="number"
        class="u-mb--1"
      />
      <BaseInput
        label="Новый пин код"
        v-model.trim="new_pin_code"
        placeholder="Введите новый пин код"
        inputId="password"
        type="number"
        class="u-mb--1"
      />
      <Button width="100%" class="u-mb--1" @click.prevent="connectToServer"> Заменить </Button>
      <Button width="100%" class="u-mb--1" @click.prevent="logout" background="--secondary">
        Выход
      </Button>
    </FormWrapper>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import api from "@/core/api";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import FormWrapper from "@/core/components/FormWrapper/FormWrapper.vue";
import BaseInput from "@/core/components/BaseInput/BaseInput.vue";
import Button from "@/core/components/Button/Button.vue";
import Separator from "@/core/components/Separator/Separator.vue";

import Logo from "@/assets/icons/logo.svg";
import { auth } from "@/core/services/auth";

const profileDataMock = {
  data: {
    name: String,
    second_name: String,
    last_name: String,
    address: String,
    email: String,
    phone: String,
  },
};

export default {
  components: { Button, FormWrapper, BaseInput, Logo, Separator },
  setup: function () {
    const old_pin_code = ref("");
    const new_pin_code = ref("");
    const codeSended = ref(false);
    const smsCode = ref("");
    const socket = ref(null);
    const cb = ref(undefined);

    /////
    const profile = ref({});
    const router = useRouter();
    const store = useStore();

    api
      .getUser()
      .then((resp) => {
        profile.value = resp.data.data;
      })
      .catch(() => {
        router.push({ name: "login" });
      });
    /////

    const tokenState = () => {
      console.log("tokenState");
      const request = {
        command: "token_state",
        callback: "qqq",
      };

      socket.value.send(JSON.stringify(request));
    };

    const changePin = () => {
      console.log("Change Pin:" + new_pin_code.value);
      if (socket.value.readyState !== 1) {
        alert("Нужно установить сервис работы с Ключами");
        return;
      }

      const auth = {
        command: "set_pin",
        parameters: {
          old_pin: old_pin_code.value,
          new_pin: new_pin_code.value,
        },
      };

      cb.value = (response) => {
        if (response.success) {
          alert("Пин изменен");
          old_pin_code.value = "";
          new_pin_code.value = "";
        }
      };

      socket.value.send(JSON.stringify(auth));
    };

    const tokenAuth = () => {
      console.log("Log in");
      if (socket.value.readyState !== 1) {
        alert("Сервис по работе с ключами не найден. Нужно установить сервис работы с Ключами");
        // window.location.href = '/sso/service/'
        return;
      }

      const auth = {
        command: "login",
        parameters: {
          pin: old_pin_code.value,
        },
      };

      cb.value = (response) => {
        if (response.success) {
          changePin();
        } else {
          alert("Пинкод не верен");
        }
      };

      socket.value.send(JSON.stringify(auth));
    };

    const logout = () => {
      auth.logOut();
    };

    const sendEmailActivationLink = () => {
      const profileData = Object.assign({}, profileDataMock);
      profileData.data = profile.value;
      api
        .saveUser(profileData)
        .then(() => {
          api.sendEmailActivation({ email: profile.value.email }).then(() => {
            alert("Проверте почту");
          });
        })
        .catch((error) => {
          if (
            error.response.data.detail === "The user with such phone already exists in the system."
          ) {
            alert("Телефон уже занят");
          }
        });
    };

    const sendCheckSmsCode = async () => {
      api
        .sendCheckSmsCode({ code: smsCode.value })
        .then(() => {
          alert("Телефон подтвержден");
          codeSended.value = false;
        })
        .catch(() => {
          alert("Код не верен");
        });
    };

    const sendSmsCode = () => {
      const profileData = Object.assign({}, profileDataMock);
      profileData.data = profile.value;
      api
        .saveUser(profileData)
        .then(() => {
          api.sendActivationSms({ phone: profile.value.phone }).then(() => {
            codeSended.value = true;
            smsCode.value = "";
          });
        })
        .catch((error) => {
          if (
            error.response.data.detail === "The user with such phone already exists in the system."
          ) {
            alert("Телефон уже занят");
          }
        });
    };

    const saveMe = () => {
      const profileData = Object.assign({}, profileDataMock);
      profileData.data = profile.value;
      api
        .saveUser(profileData)
        .then(() => {
          alert("Сохранено");
        })
        .catch(() => {
          alert("Ошибка сохранения");
        });
    };

    const connectToServer = () => {
      if (socket.value != null) {
        tokenAuth();
        return;
      }

      socket.value = new WebSocket("ws://127.0.0.1:8090/");
      console.log("WebSocket Connecting...");
      socket.value.onopen = () => {
        console.log("WebSocket Connected OK");
        tokenState();
      };

      socket.value.onmessage = (event) => {
        let resp = JSON.parse(event.data);
        console.log(resp);
        console.log("WS Callback", cb.value);

        let callback = cb.value;
        cb.value = undefined;
        if (callback != null && typeof callback !== "undefined") {
          callback(resp);
        } else if (resp.token_connected) {
          tokenAuth();
        } else if (!resp.token_connected) {
          alert("Нужно подключить USB Ключ Jakarta");
        }
      };

      socket.value.onclose = function () {
        alert("Вебсокет Закрыт, обновите страницу");
        console.log("WebSocket Disconnected");
      };
    };

    return {
      old_pin_code,
      new_pin_code,
      socket,
      connectToServer,
      logout,
      profile,
      sendSmsCode,
      smsCode,
      sendCheckSmsCode,
      codeSended,
      sendEmailActivationLink,
      saveMe,

      // core$main - название модуля в /core/stores/index.js
      globalStore: computed(() => store.getters["core$main/globalState"]),
    };
  },
  beforeUnmount() {
    console.log("close socket");
    if (this.socket && this.socket.readyState === 0) {
      this.socket.close();
    }
  },
};
</script>

<style lang="scss" scoped src="./Profile.scss"></style>
